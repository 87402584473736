<template>
  <vl-map :load-tiles-while-animating="true" :load-tiles-while-interacting="true" data-projection="EPSG:4326" style="height: 400px">
    <vl-view :zoom.sync="zoom" :center.sync="center" :rotation.sync="rotation"></vl-view>

    <vl-layer-tile>
      <vl-source-xyz :url="url"></vl-source-xyz>
    </vl-layer-tile>

    <vl-layer-tile id="wmts">
      <vl-source-wmts :attributions="attribution" :url="url" :layer-name="layerName" :matrix-set="matrixSet" :format="format" 
                      :style-name="styleName"></vl-source-wmts>
    </vl-layer-tile>
  </vl-map>
  <!-- <section class="section">
    <div class="columns">
      <div class="column">
        <div class="card">
          <div class="card-image">
            <figure class="image is-4by3"> <img src="https://source.unsplash.com/random/800x600" alt="Image"> </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-left">
                <figure class="image" style="height: 40px; width: 40px;"> <img src="https://source.unsplash.com/random/96x96" alt="Image"> </figure>
              </div>
              <div class="media-content">
                <p class="title is-4">John Smith</p>
                <p class="subtitle is-6">@johnsmith</p>
              </div>
            </div>
            <div class="content"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec iaculis mauris.
              <a>@bulmaio</a>.
              <a>#css</a>
              <a>#responsive</a>
              <br> <small>11:09 PM - 1 Jan 2016</small> </div>
          </div>
        </div>
        <br><br>
        <nav class="level">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Tweets</p>
              <p class="title">3,456</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Following</p>
              <p class="title">123</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Followers</p>
              <p class="title">456K</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Likes</p>
              <p class="title">789</p>
            </div>
          </div>
        </nav>
        <br><br>
        <section>
          <b-field grouped group-multiline>
              <div class="control">
                  <b-switch v-model="isBordered">Bordered</b-switch>
              </div>
              <div class="control">
                  <b-switch v-model="isStriped">Striped</b-switch>
              </div>
              <div class="control">
                  <b-switch v-model="isNarrowed">Narrowed</b-switch>
              </div>
              <div class="control">
                  <b-switch v-model="isHoverable">Hoverable</b-switch>
              </div>
              <div class="control">
                  <b-switch v-model="isFocusable">Focusable</b-switch>
              </div>
              <div class="control">
                  <b-switch v-model="isLoading">Loading state</b-switch>
              </div>
              <div class="control">
                  <b-switch v-model="isEmpty">Empty</b-switch>
              </div>
              <div class="control">
                  <b-switch v-model="hasMobileCards">Mobile cards <small>(collapsed rows)</small></b-switch>
              </div>
          </b-field>

          <b-table
              :data="isEmpty ? [] : data"
              :bordered="isBordered"
              :striped="isStriped"
              :narrowed="isNarrowed"
              :hoverable="isHoverable"
              :loading="isLoading"
              :focusable="isFocusable"
              :mobile-cards="hasMobileCards">

              <template slot-scope="props">
                  <b-table-column field="id" label="ID" width="40" numeric>
                      {{ props.row.id }}
                  </b-table-column>

                  <b-table-column field="first_name" label="First Name">
                      {{ props.row.first_name }}
                  </b-table-column>

                  <b-table-column field="last_name" label="Last Name">
                      {{ props.row.last_name }}
                  </b-table-column>

                  <b-table-column field="date" label="Date" centered>
                      <span class="tag is-success">
                          {{ new Date(props.row.date).toLocaleDateString() }}
                      </span>
                  </b-table-column>

                  <b-table-column label="Gender">
                      <b-icon pack="fas"
                          :icon="props.row.gender === 'Male' ? 'mars' : 'venus'">
                      </b-icon>
                      {{ props.row.gender }}
                  </b-table-column>
              </template>

              <template slot="empty">
                  <section class="section">
                      <div class="content has-text-grey has-text-centered">
                          <p>
                              <b-icon
                                  icon="emoticon-sad"
                                  size="is-large">
                              </b-icon>
                          </p>
                          <p>Nothing here.</p>
                      </div>
                  </section>
              </template>
          </b-table>
      </section>
      </div>
      <div class="column">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title"> Component </p>
            <a class="card-header-icon">
              <span class="icon"> <i class="fa fa-angle-down"></i> </span>
            </a>
          </header>
          <div class="card-content">
            <div class="content"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec iaculis mauris.
              <a>@bulmaio</a>.
              <a>#css</a>
              <a>#responsive</a>
              <br> <small>11:09 PM - 1 Jan 2016</small> </div>
          </div>
          <footer class="card-footer">
            <a class="card-footer-item">Save</a>
            <a class="card-footer-item">Edit</a>
            <a class="card-footer-item">Delete</a>
          </footer>
        </div>
        <br>
        <el-switch
          v-model="value3"
          active-text="Paiement mensuel"
          inactive-text="Paiement annuel">
        </el-switch>
        <div class="block">
          <span class="demonstration">Valeur par défaut</span>
          <el-slider v-model="value1"></el-slider>
        </div>
        <el-time-picker
          is-range
          v-model="value4"
          range-separator=" à "
          start-placeholder="Horaire de début"
          end-placeholder="Horaire de fin">
        </el-time-picker>
        <br>
        <br>
        <div class="block">
          <span class="demonstration">Défaut</span>
          <el-date-picker
            v-model="value1"
            type="date"
            placeholder="Choississez un jour">
          </el-date-picker>
        </div>
        <div class="block">
          <span class="demonstration">Défaut</span>
          <el-rate v-model="value1"></el-rate>
        </div>
        <br><br>
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item label="Activity name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="Activity zone">
            <el-select v-model="form.region" placeholder="please select your zone">
              <el-option label="Zone one" value="shanghai"></el-option>
              <el-option label="Zone two" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Activity time">
            <el-col :span="11">
              <el-date-picker type="date" placeholder="Pick a date" v-model="form.date1" style="width: 100%;"></el-date-picker>
            </el-col>
            <el-col class="line" :span="2">-</el-col>
            <el-col :span="11">
              <el-time-picker placeholder="Pick a time" v-model="form.date2" style="width: 100%;"></el-time-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="Instant delivery">
            <el-switch v-model="form.delivery"></el-switch>
          </el-form-item>
          <el-form-item label="Activity type">
            <el-checkbox-group v-model="form.type">
              <el-checkbox label="Online activities" name="type"></el-checkbox>
              <el-checkbox label="Promotion activities" name="type"></el-checkbox>
              <el-checkbox label="Offline activities" name="type"></el-checkbox>
              <el-checkbox label="Simple brand exposure" name="type"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="Resources">
            <el-radio-group v-model="form.resource">
              <el-radio label="Sponsor"></el-radio>
              <el-radio label="Venue"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="Activity form">
            <el-input type="textarea" v-model="form.desc"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">Create</el-button>
            <el-button>Cancel</el-button>
          </el-form-item>
        </el-form>
        <el-table
          :data="tableData"
          stripe
          style="width: 100%">
          <el-table-column
            prop="date"
            label="Date"
            width="180">
          </el-table-column>
          <el-table-column
            prop="name"
            label="Nom"
            width="180">
          </el-table-column>
          <el-table-column
            prop="address"
            label="Adresse">
          </el-table-column>
        </el-table>
        <br><br>
        <el-steps :active="1">
          <el-step title="Étape 1" description="Une description"></el-step>
          <el-step title="Étape 2" description="Une description"></el-step>
          <el-step title="Étape 3" description="Une description"></el-step>
        </el-steps>
        <br><br>
        <el-row>
          <el-col :span="8" v-for="(o, index) in 2" :key="o" :offset="index > 0 ? 2 : 0">
            <el-card :body-style="{ padding: '0px' }">
              <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png" class="image">
              <div style="padding: 14px;">
                <span>Yummy hamburger</span>
                <div class="bottom clearfix">
                  <time class="time">{{ currentDate }}</time>
                  <el-button type="text" class="button">Operating</el-button>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <br><br>
        <el-collapse v-model="activeName" accordion>
            <el-collapse-item title="Cohérence" name="1">
              <div>Cohérence avec la vraie vie: en accord avec les processus habituels de la vie réelle, conforme aux langages et habitudes des utilisateurs;</div>
              <div>Cohérence au sein de l'interface: tout les éléments doivent être cohérents entre eux et suivre les mêmes règles, par exemple: le style global, les icônes, la position des éléments, etc.</div>
            </el-collapse-item>
            <el-collapse-item title="Feedback" name="2">
              <div>Retour d'expèrience: permets aux utilisateurs de percevoir clairement leur opérations par le biais d'animations et d'effets interactifs.</div>
              <div>Retour visuel: reflète l'état actuel de la page via le réarrangement ou la mise à jour des éléments.</div>
            </el-collapse-item>
            <el-collapse-item title="Efficacité" name="3">
              <div>Simplifier le processus: garde chaque operation simple et intuitive.;</div>
              <div>Clair et défini: énonce clairement ses intentions afin que l'utilisateur puisse comprendre et prendre une décision rapidement;</div>
              <div>Facile à identifier: l'interface devrait être simple et facile d'accès, afin que les utilisateurs n'ai pas d'efforts de mémorisation à faire.</div>
            </el-collapse-item>
            <el-collapse-item title="Controllabilité" name="4">
              <div>Prise de décision: possibilité de donner des conseils sur les opérations, mais ne jamais prendre de décisions à la place des utilisateurs;</div>
              <div>Contrôle des conséquences: les utilisateurs devraient pouvoir contrôler l'exécution de leurs opérations, y compris l'annulation et la suppression des opérations courantes.</div>
            </el-collapse-item>
        </el-collapse>
        <br><br>
        <div class="block">
          <el-timeline>
            <el-timeline-item timestamp="2018/4/12" placement="top">
              <el-card>
                <h4>Mise à jour du template GitHub</h4>
                <p>Commit de Tom le 2018/4/12 20:46</p>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2018/4/3" placement="top">
              <el-card>
                <h4>Mise à jour du template GitHub</h4>
                <p>Commit de Tom le 2018/4/3 20:46</p>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2018/4/2" placement="top">
              <el-card>
                <h4>Mise à jour du template GitHub</h4>
                <p>Commit de Tom le 2018/4/2 20:46</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>
  </section> -->
</template>

<script>
  export default {
    data () {
      const data = [
        { 'id': 1, 'first_name': 'Jesse', 'last_name': 'Simmons', 'date': '2016/10/15 13:43:27', 'gender': 'Male' },
        { 'id': 2, 'first_name': 'John', 'last_name': 'Jacobs', 'date': '2016/12/15 06:00:53', 'gender': 'Male' },
        { 'id': 3, 'first_name': 'Tina', 'last_name': 'Gilbert', 'date': '2016/04/26 06:26:28', 'gender': 'Female' },
        { 'id': 4, 'first_name': 'Clarence', 'last_name': 'Flores', 'date': '2016/04/10 10:28:46', 'gender': 'Male' },
        { 'id': 5, 'first_name': 'Anne', 'last_name': 'Lee', 'date': '2016/12/06 14:38:38', 'gender': 'Female' }
      ]

    return {
      zoom: 10,
      center: [ 55.59, -21.13 ],
      rotation: 0,
      // url: 'https://services.arcgisonline.com/arcgis/rest/services/Demographics/USA_Population_Density/MapServer/WMTS/',
      url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      layerName: '0',
      matrixSet: 'EPSG:3857',
      format: 'image/png',
      styleName: 'default',
      attribution: 'Tiles © <a href="https://services.arcgisonline.com/arcgis/rest/' +
                                      'services/Demographics/USA_Population_Density/MapServer/">ArcGIS</a>',
      isEmpty: false,
      isBordered: false,
      isStriped: false,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      token: null,
      activeName: '1',
      // url: conf.API_URL + 'user/',
      data,
      name: '',
      value1: '',
      value3: '',
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      tableData: [{
          date: '2016-05-03',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles'
        }, {
          date: '2016-05-02',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles'
        }, {
          date: '2016-05-04',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles'
        }, {
          date: '2016-05-01',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles'
      }]
    }
  }
}
</script>